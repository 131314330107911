<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>ACL Trigger Search</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addAclTrigger">Add ACL Trigger</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>ACL Name</label>
              <b-form-input v-model="search.triggerName"></b-form-input>
            </b-col>
            <b-col cols="4">
              <label>Next Scheduled Date From</label>
              <b-form-datepicker v-model="search.nextScheduleDateFrom"></b-form-datepicker>
            </b-col>
            <b-col cols="4">
              <label>Next Scheduled Date To</label>
              <b-form-datepicker v-model="search.nextScheduleDateTo"></b-form-datepicker>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Company</label>
              <b-input-group>
                <b-form-input v-model="search.client" :disabled="true"></b-form-input>
                <b-input-group-append>
                  <b-button size="sm" text="Button" variant="secondary" @click="openSearchModal()">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <label>Client Account Number</label>
              <b-form-input></b-form-input>
            </b-col>
          </b-row>
          <hr class="mx-3" />
          <b-row>
            <b-col>
              <div class="d-flex justify-content-end">
                <div>
                  <b-button variant="red" squared class="ml-2" @click="clearSearchFields()">Clear</b-button>
                </div>
                <div>
                  <b-button variant="primary" squared class="ml-2" @click="doSearchTrigger()">Search</b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <div class="d-flex justify-content-between">
                      <h4>Results</h4>
                      <!-- <download-csv
                              :data   = "jsonData"
                              :name    = fileName>

                                <b-button variant="primary" squared class="text-right" @click="downloadMessage">Export to .csv</b-button>

                          </download-csv> -->
                      <b-button variant="primary" squared class="text-right" @click="downloadFile"
                        >Download Results</b-button
                      >
                    </div>
                    <b-table
                      striped
                      hover
                      :items="tableData.dataSource"
                      :fields="tableData.tableColumns"
                      :busy="tableData.isLoading"
                      @row-clicked="openAclTrigger"
                      :per-page="tableData.resultsPerPage"
                      id="aclSearch"
                      :current-page="tableData.currentPage"
                      sort-icon-left>
                      <template #table-busy>
                        <div class="text-center my-2">
                          <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
                        </div>
                      </template>

                      <template #cell(nextScheduleDate)="row">
                        {{ row.item.nextScheduleDate | dateTimeFilter }}
                      </template>
                      <template #cell(lastActionDate)="row">
                        {{ row.item.lastActionDate | dateTimeFilter }}
                      </template>

                      <template #cell(company)="row">
                        {{ row.item.company === null ? 'N/A' : row.item.company.description }}
                      </template>
                      <template #cell(accountNumber)="row">
                        {{ row.item.clientContact === null ? 'N/A' : row.item.clientContact.clientAccountNumber }}
                      </template>
                      <template #cell(locationName)="row">
                        {{ row.item.locationName === null ? 'N/A' : row.item.locationName }}
                      </template>

                      <template #cell(actions)="row">
                        <b-row align-v="center" align-h="end">
                          <b-button @click="openAclTrigger(row.item)" size="sm" class="btn-icon">
                            <b-icon-chevron-right></b-icon-chevron-right>
                          </b-button>
                        </b-row>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="aclSearch"
                  ></b-pagination>
                </b-row>
              </b-card>
      </b-col>
    </b-row>

    <b-modal id="search-company-modal" hide-footer size="xl" no-header @close="closeSearchModal()">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="companySearch.name"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2" @click="clearCompanyFilter()">Clear</b-button>
          <b-button variant="primary" squared @click="doSearchCompanies()">Search</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          striped
          hover
          :items="tableCompanyData.dataSource"
          :fields="tableCompanyData.tableColumns"
          :busy="tableCompanyData.isLoading"
          :per-page="tableCompanyData.resultsPerPage"
          :current-page="tableCompanyData.currentPage"
          @row-clicked="selectClient"
          sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
            </div>
          </template>

          <template #cell(actions)="row">
            <b-row align-v="center" align-h="end">
              <b-button @click="selectClient(row.item)" size="sm" class="btn-icon">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>
        </b-table>
      </b-row>
      <b-row align-h="center">
        <b-pagination
          v-model="tableCompanyData.currentPage"
          :total-rows="companyRows"
          :per-page="tableCompanyData.resultsPerPage"
        ></b-pagination>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-right">
          <b-button variant="red" class="mr-2" squared @click="closeSearchModal()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import JsonCSV from 'vue-json-csv';
import { mapActions, mapMutations } from 'vuex';

Vue.component('downloadCsv', JsonCSV);

export default {
  name: 'aclTriggerSearch',
  data: () => ({
    search: {
      triggerName: null,
      nextScheduleDateTo: null,
      nextScheduleDateFrom: null,
      clientId: null,
      client: null,
    },
    companySearch: {
        name: null
      },
    tableData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'company',
          sortable: true,
          tdClass: 'd-flex align-items-center',
        },
        {
          label: 'Client Account No',
          key: 'clientAccountNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Location',
          key: 'locationName',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'ACL Name',
          key: 'triggerName',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Next Scheduled For',
          key: 'nextScheduleDate',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Last Actioned',
          key: 'lastActionDate',
          sortable: true,
          tdClass: '',
        },
        // {
        //     label: 'Tech',
        //     key: 'technitianName',
        //     sortable: true,
        //     tdClass: '',
        // },
        // {
        //     label: 'Tech Group',
        //     key: 'technitianGroup',
        //     sortable: true,
        //     tdClass: '',
        // },
        {
          label: 'Request Type',
          key: 'requestType.description',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Active',
          key: 'active',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
    tableCompanyData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
    jsonData: [],
    fileName: `ACL Trigger ${new Date().toLocaleDateString('en-ZA')}.csv`,
  }),
  created() {
    this.setBreadcrumb([
      {
        text: 'ACL Trigger',
      },
      {
        text: 'Search',
      },
    ]);
    this.doSearchTrigger();
  },
  methods: {
    ...mapActions(['searchAclTriggers', 'searchCompanies', 'downloadAclTriggers']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage', 'addSuccessMessage']),

    openAclTrigger(rowItem) {
      this.$store.commit('setSelectedAclTrigger', rowItem);
      this.$router.push({ path: '/acl/triggerView' });
    },
    openSearchModal() {
      this.$root.$emit('bv::show::modal', 'search-company-modal', '#btnShow');
      this.doSearchCompanies();
    },
    closeSearchModal() {
      this.$root.$emit('bv::hide::modal', 'search-company-modal', '#btnShow');
    },
    selectClient(clientSelected) {
      //console.log('test', clientSelected);

      this.search.clientId = clientSelected.id;
      this.search.client = clientSelected.description;
      this.closeSearchModal();
    },
    doSearchCompanies() {
      this.tableCompanyData.isLoading = true;
      let request = [];
      if (this.companySearch.name !== null) {
        request.push({ key: 'companyName', value: this.companySearch.name });
      }
      this.$store.commit('setSearchCompaniesRequest', request);
      this.searchCompanies()
        .then((res) => {
          this.tableCompanyData.dataSource = res.data;
          this.tableCompanyData.isLoading = false;
        })
        .catch(() => {
          this.tableCompanyData.isLoading = false;
        });
    },
    clearCompanyFilter() {
      this.companySearch = {
        name: null,
      };
      this.doSearchCompanies();
    },
    addAclTrigger() {
      this.$store.commit('setSelectedAclTrigger', {
        ticketSatusId: null,
        active: false,
        clientId: null,
        client: { name: null },
        triggerName: null,
        // technicianGroup: null,
        originId: null,
        // technitianGroupId: null,
        callTypeId: null,
        contractTypeId: null,
        priorityId: null,
        requestTypeId: null,
        scheduleDayOfWeek: null,
        scheduleTypeId: null,
      });

      this.$router.push({ path: '/acl/triggerCreate' });
    },
    downloadFile() {
      this.downloadAclTriggers()
        .then((res) => {
          //console.log(res);

          const linkSource = `data:${res.data.contentType};base64,${res.data.base64Data}`;
          const downloadLink = document.createElement('a');

          downloadLink.href = linkSource;
          downloadLink.download = 'ACL Triggers Search Results';
          downloadLink.click();
        })
        .catch((err) => {
          console.log("Error",err);
        });
    },
    downloadMessage() {
      //console.log('json data', this.jsonData);
      if (this.jsonData.length === 0) {
        this.addWarningMessage('There is no data to be downloaded');
      } else {
        this.addSuccessMessage('Your content has been downloaded ');
      }
    },
    clearSearchFields() {
      this.search = {
        triggerName: null,
        nextScheduleDateFrom: null,
        nextScheduleDateTo: null,
        clientId: null,
      };
    },
    doSearchTrigger() {
      this.tableData.isLoading = true;

      let request = [];
      if (this.search.triggerName !== null) {
        request.push({ key: 'triggerName', value: this.search.triggerName });
      }
      if (this.search.nextScheduleDateFrom !== null) {
        request.push({ key: 'nextScheduleDateFrom', value: this.search.nextScheduleDateFrom });
      }
      if (this.search.nextScheduleDateTo !== null) {
        request.push({ key: 'nextScheduleDateTo', value: this.search.nextScheduleDateTo });
      }
      if (this.search.clientId !== null) {
        request.push({ key: 'fkcompanyId', value: this.search.clientId });
      }

      this.$store.commit('setSearchAclTriggerRequest', request);
      this.searchAclTriggers()
        .then((res) => {
          this.tableData.dataSource = res.data;

          // //console.log('data', res.data)

          // this.jsonData = res.data.map( v => ({
          //     Company: v.company === null ? 'N/A' : v.company.description,
          //     ClientAccountNumber: v.clientContact === null ? 'N/A' : v.clientContact.clientAccountNumber,
          //     Location: v.clientContact === null ? 'N/A' : v.clientContact.location,
          //     ScheduledFor: v.nextScheduleDate,
          //     LastActioned: v.lastActionDate,
          //     Technitian: v.technitianName,
          //     TechnitianGroup: v.technitianGroup,
          //     RequestType: v.requestType === null ? 'N/A' : v.requestType.description,
          //     IsActive: v.active
          // }))
          this.tableData.isLoading = false;
        })
        .catch((err) => {
          console.log("Error",err);
          this.tableData.isLoading = false;
        });
    },
  },
  computed: {
    rows() {
      return this.tableData.dataSource.length;
    },
    companyRows() {
      return this.tableCompanyData.dataSource.length;
    },
  },
};
</script>

<style scoped></style>
